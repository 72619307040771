.App-header {
    padding: 1rem 13rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
        padding: 0 1rem;
    }

    .flex-box {
        display: flex;
        align-items: center;

        @media (max-width: 768px) {
            width: 70%;
        }
    }

    .logo-image {
        width: 150px;
        border-right: 3px solid #e6e8ec;
        padding-right: 2rem;
        margin: 0.5rem 2rem 0.5rem 0;

        @media (max-width: 768px) {
            width: 40%;
            padding-right: 0.5rem;
            margin: 0.5rem 0.7rem 0.5rem 0;
            border: none;
        }

        img {
            object-fit: cover;
            object-position: 0 55%;
            height: 42px;

            @media (max-width: 768px) {
                object-position: 0 50%;
            }
        }
    }

    input {
        border-radius: 20px;
        padding: 0.5rem 2rem;
        background: none;
        border-image: none;
        border: 2px solid #e6e8ec;
        width: 300px;
        height: 2.25rem;

        @media (max-width: 768px) {
            display: none;

            border-radius: 9px;
            padding: 0.3rem 0.6rem;
            background: none;
            border-image: none;
            border: 2px solid #e6e8ec;
            width: 55%;
            font-size: 12px;
            height: 1.8rem;
        }
    }

    .minting-button {
        background-color: #2484FF;
        border: none;
        color: #fff;
        padding: 0.6rem 0.8rem;
        border-radius: 1rem;
        font-weight: bold;
        cursor: pointer;
    }
}
