.Home {
    main {
        .main-header {
            position: relative;
            display: flex;

            img.desktop {
                @media (max-width: 768px) {
                    display: none;
                }
            }

            img.mobile {
                display: none;
                @media (max-width: 768px) {
                    display: block;
                    object-fit: cover;
                    height: 170px;
                }
            }

            .header-title {
                position: absolute;
                color: #fff;
                top: 50%;
                right: 17%;
                transform: translate(-50%, -50%);
                font-family: "Noto Sans Kr Bold";
                font-size: 21px;

                @media (max-width: 768px) {
                    top: 50%;
                    right: 2.5rem;
                    transform: translateY(-50%);
                    font-size: 15px;
                }

                p {
                    margin: 0;
                }

                p:nth-child(1) {
                    font-size: 35px;
                    @media (max-width: 768px) {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .NFT-card-deck-wrapper {
        background-color: #eeeeee;
        padding: 3rem 7rem;

        @media (max-width: 768px) {
            padding: 1rem;
        }

        .deck-title {
            font-family: "Noto Sans Kr Bold";
            font-size: 22px;

            @media (max-width: 768px) {
                font-size: 15px;
            }
        }
    }

    .other-genre {
        background-color: #ffffff;
    }

    .NFT-card-deck {
        display: grid;
        justify-content: center;
        align-items: center;
        padding: 3rem 0;
        grid-gap: 16px;
        grid-template-columns: repeat(4, minmax(100px, 1fr));

        @media (max-width: 768px) {
            padding: 1rem 0;
            grid-template-columns: repeat(1, minmax(100px, 1fr));
        }
    }
}
