@font-face {
    font-family: "Noto Sans Kr";
    src: local("Noto Sans Kr"),
        url(./assets/font/Noto_Sans_KR/NotoSansKR-Medium.otf) format("opentype");
}

@font-face {
    font-family: "Noto Sans Kr Bold";
    src: local("Noto Sans Kr Bold"),
        url(./assets/font/Noto_Sans_KR/NotoSansKR-Bold.otf) format("opentype");
}

.App {
    .image-wrapper {
        img {
            width: 100%;
        }
    }
}
