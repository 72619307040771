.Mint {
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
	border-top: 1px solid #eee;
    margin-top: 0.1rem;

    h1 {
        font-size: 2rem;
        font-weight: 100;
        letter-spacing: 1px;
    }

    & > section {
        padding: 1rem;
    }

    section.main-input {
        width: 75%;

        @media (max-width: 768px) {
            width: 100%;
        }

        .input-box {
            margin: 2rem 0;

            .title {
                font-weight: 700;
                margin-bottom: 0.2rem;

                &::after {
                    content: "*";
                    color: #d80000;
                    margin: 0 0 0 0.3rem;
                }
            }

            .sub-title {
                color: #6e6e6e;
                font-size: 0.8rem;
                margin-bottom: 0.8rem;
            }

            .input input {
                padding: 0.7rem 1.1rem;
                border-radius: 10px;
                border: 1px solid #c5c5c5;
                width: 100%;
            }

            .dzu-submitButton {
                color: #2484ff;
                position: relative;

                &::after {
                    content: "Cancel";
                    position: absolute;
                    color: #fff;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

        .description {
            height: 25rem;

            @media (max-width: 768px) {
                height: 32rem;
            }

            .mobile-preview {
                display: none;
                text-align: center;
                padding: 1rem;

                @media (max-width: 768px) {
                    display: block;
                }

                button {
                    padding: 0.5rem 1rem;
                    background-color: #2484ff;
                    border: none;
                    border-radius: 4px;
                    color: #fff;
                }
            }

            .ql-container {
                height: 20rem;

                @media (max-width: 768px) {
                    height: 15rem;
                }
            }
        }
    }

    section.preview {
        width: 25%;

        @media (max-width: 768px) {
            display: none;
            position: fixed;
            background: #fff;
            z-index: 100;
            width: 95%;
            left: 50%;
            transform: translateX(-50%);
        }

        .NFT-card {
            position: sticky;
            top: 0;

            .image-wrapper {
                max-height: 300px;
                overflow: hidden;
            }

            .content-image {
                object-fit: cover;
            }
        }
    }

    section.preview.mobile-view {
        @media (max-width: 768px) {
            display: block;
        }
    }
}
