.NFT-card {
    padding: 1rem;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    background: #fff;

    cursor: pointer;

    .creator-section {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        padding: 0 0.5rem;
        align-items: center;

        & > div {
            width: 50%;
        }

        .logo-section {
            display: flex;
            align-items: center;

            .creator-image {
                width: 20%;
                margin-right: 0.5rem;
            }
        }

        .like-section {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .like-heart {
                width: 18px;
                height: 18px;
                margin-right: 6px;
            }
        }
    }

    .content-section {
        .image-wrapper {
            border-radius: 10px;
            margin-bottom: 0.5rem;

			.content-image {
				border-radius: 10px;
			}
        }

        .title {
            font-weight: 700;
            font-size: 14px;
            margin: 0;
            margin-bottom: 4px;
            width: 100%;
            padding-right: 3px;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .created-wrapper {
            font-size: 10px;
            padding-bottom: 1rem;
            border-bottom: 1px solid #f0f0f0;
        }

        .created-title {
            margin-right: 4px;
            color: #838383;
            min-width: 53px;
        }

        .created-name {
            color: #000;
            overflow: hidden;
            padding-right: 3px;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 60%;
            display: inline-block;
            vertical-align: bottom;
        }
    }

    .footer-section {
        text-align: right;

        & > p {
            margin: 0;
        }

        .edition {
            font-size: 10px;
            color: #838383;
        }

        .number {
            font-size: 14px;
            font-weight: 700;
            word-break: break-word;
        }
    }
}
